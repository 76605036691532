import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { StyledOrderBlock, StyledBottomBlock } from "./style";
import rightArrow from "../../assets/images/right-arrow.png";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsByOrderId, resendMail, uploadVisaDocument } from "../../features/order/orderSlice";
import moment from "moment";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import AddComment from "./AddComment/AddComment";
import MyModal from "../Modal/Modal";
import EmailContent from "./EmailContent/EmailContent";

const ViewOrders = () => {
  const dispatch = useDispatch();
  const { orderId } = useParams();
  const [show, setShow] = useState(false);

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  const isLoading = useSelector((state) => state?.order?.loading);

  const [selectedData, setSelectedData] = useState({
    showData: false,
    dataId: 0,
  });

  const [emailId, setEmailId] = useState(OrderDetails?.contact_email_address);
  const [orderid, setEmailIdOrderid] = useState(OrderDetails?.order_id);

  const uploadSchema = yup.object({
    email: yup.string().required("Please enter your email"),
    fileUpload: yup.mixed().required("File is required"),
  });

  useEffect(() => {
    if (orderId) {
      dispatch(getOrderDetailsByOrderId(orderId));
    }

    document.title = "Orders Details | Vietnam E-visa";
  }, [dispatch, orderId]);

  useEffect(() => {
    if (
      (OrderDetails &&
        typeof OrderDetails !== "undefined" &&
        OrderDetails?.contact_email_address &&
        typeof emailId === "undefined") ||
      orderId !== orderid
    ) {
      setEmailId(OrderDetails?.contact_email_address);
    }
  }, [OrderDetails, orderid]);

  let defaultInitialValues = {
    orderId: OrderDetails?.order_id,
    email: "",
    fileUpload: "",
  };

  if (orderId !== null && orderId !== undefined && OrderDetails !== null) {
    defaultInitialValues = {
      orderId: OrderDetails?.order_id,
      email: OrderDetails?.contact_email_address,
      fileUpload: "",
    };
  }

  let [initialValues, setInitialValues] = useState(defaultInitialValues);

  const { values, errors, handleBlur, handleSubmit, handleChange, setFieldValue, resetForm } = useFormik({
    initialValues: orderId !== null ? defaultInitialValues : initialValues,
    enableReinitialize: true,
    validationSchema: uploadSchema,
    onSubmit: (values) => {
      const data = new FormData();
      data.append("orderId", values.orderId);
      data.append("email", values.email);
      data.append("uploadVisaDoc", values.fileUpload);
      dispatch(uploadVisaDocument(data)).unwrap().then((res) => {
        resetForm();
        if (res.status === 1) {
          toast.success(`${res.message}`, {
            className: "toast-message",
          });
          dispatch(getOrderDetailsByOrderId(orderId));
        }
      });
    },
  });

  const handleResendMail = (e) => {
    e.preventDefault();
    const data = {
      orderId: OrderDetails?.order_id,
      email: emailId,
    };
    dispatch(resendMail(data)).unwrap().then((res) => {
      if (res.status === 1) {
        toast.success(`${res.message}`, {
          className: "toast-message",
        });
        dispatch(getOrderDetailsByOrderId(orderId));
      }
    });
  };

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {OrderDetails?.mailHistory?.length !== 0 && (
            <StyledOrderBlock>
              <h2>Email History</h2>
              <div className="table-block table-responsive">
                <table>
                  <thead>
                    <tr>
                      <th style={{ width: "10%" }}>#</th>
                      <th style={{ width: "15%" }}>Order ID</th>
                      <th style={{ width: "35%" }}>Download</th>
                      <th style={{ width: "10%" }}>Date & Time</th>
                    </tr>
                  </thead>
                  <tbody>
                    {OrderDetails?.mailHistory?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td className="font-normal">{item?.order_id}</td>
                          {OrderDetails?.uploadDoc?.length ? (
                            <td>
                              <a
                                href={
                                  OrderDetails?.length !== 0
                                    ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH
                                    }docs/${item?.order_id}_${item?.uploadDoc == null
                                      ? OrderDetails?.uploadDoc[0]?.file1
                                      : item?.uploadDoc.file1
                                    }`
                                    : null
                                }
                                className="blue-btn"
                                download
                                rel="noreferrer"
                              >
                                Download File{" "}
                              </a>
                            </td>
                          ) : (
                            ""
                          )}
                          <td>
                            {moment(item?.create_ts).format(
                              "MM-DD-YYYY h:mm:ss"
                            )}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
                <form className="form-inline">
                  <div className="form-group">
                    <label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="form-control input-class"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                      />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      type="submit"
                      className="blue-btn"
                      id="download_btn"
                      disabled={isLoading}
                      onClick={handleResendMail}
                    >
                      Resend Mail{" "}
                      <span>
                        <img src={rightArrow} alt="rightArrow" />
                      </span>
                    </button>
                  </div>
                </form>
              </div>
            </StyledOrderBlock>
          )}
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Upload Document</h2>
            <div className="table-block table-responsive fw-mobileview">
              <form onSubmit={handleSubmit}>
                <table>
                  <tbody>
                    <tr>
                      <td style={{ width: "15%" }}>Order ID</td>
                      <td style={{ width: "35%" }} className="font-normal">
                        <input
                          type="text"
                          name="orderId"
                          className="form-control"
                          placeholder="Order ID"
                          disabled
                          value={values?.orderId}
                        />
                      </td>
                      <td style={{ width: "15%" }}>Email ID</td>
                      <td style={{ width: "35%" }}>
                        <input
                          type="email"
                          name="email"
                          className="form-control"
                          value={values?.email}
                          onChange={handleChange}
                          placeholder="Email"
                        />
                        <p>{errors.email}</p>
                      </td>
                    </tr>

                    <tr>
                      <td>PDF</td>
                      <tr>
                        <td>
                          <label className="file-upload">
                            <input
                              type="file"
                              className=""
                              id="fileUpload"
                              name="fileUpload"
                              accept=".pdf,image/*"
                              onChange={(e) =>
                                setFieldValue(
                                  "fileUpload",
                                  e.currentTarget.files[0]
                                )
                              }
                              onBlur={handleBlur}
                            />
                            <span>Choose File</span>
                          </label>
                          <span className="slcted-file"></span>
                          <p>{errors.fileUpload}</p>
                        </td>
                      </tr>
                    </tr>
                    {OrderDetails?.uploadDoc?.length ? (
                      <td colSpan="2">
                        <a
                          href={
                            OrderDetails?.length !== 0
                              ? `${process.env.REACT_APP_BASE_URL}/${process.env.REACT_APP_PUBLIC_UPLOAD_PATH}docs/${OrderDetails?.order_id}_${OrderDetails?.uploadDoc[0]?.file1}`
                              : null
                          }
                          className="blue-btn"
                          download
                          rel="noreferrer"
                        >
                          Download File{" "}
                        </a>
                      </td>
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
                <br />
                <button
                  type="submit"
                  className="blue-btn"
                  id="submit_btn"
                  disabled={isLoading}
                >
                  Submit{" "}
                  <span>
                    <img src={rightArrow} alt="rightArrow" />
                  </span>
                </button>
              </form>
            </div>
          </StyledOrderBlock>
        </div>
        <div className="col-md-12">
          <StyledOrderBlock>
            <h2>Order Details</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.order_id && (
                    <tr>
                      <td>Order ID:</td>
                      <td>{OrderDetails?.order_id}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Personal and Contact Information</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.first_name && (
                    <tr>
                      <td>Name</td>
                      <td>{OrderDetails?.first_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.sur_name && (
                    <tr>
                      <td>Surname  </td>
                      <td>{OrderDetails?.sur_name}</td>
                    </tr>
                  )}

                  {OrderDetails?.contact_email_address && (
                    <tr>
                      <td>What email address do you want to use to receive your ETA decision ?</td>
                      <td>{OrderDetails?.contact_email_address}</td>
                    </tr>
                  )}

                  {OrderDetails?.confirm_contact_email_address && (
                    <tr>
                      <td>Confirm email address do you want to use to receive your ETA decision ?  </td>
                      <td>{OrderDetails?.confirm_contact_email_address}</td>
                    </tr>
                  )}

                  {OrderDetails?.phone_number && (
                    <tr>
                      <td>What is your phone number ? </td>
                      <td>{`+${OrderDetails?.country_code} ${OrderDetails?.phone_number}`}</td>
                    </tr>
                  )}

                  {OrderDetails?.nationality && (
                    <tr>
                      <td>Nationalities</td>
                      <td>
                        {OrderDetails?.nationality}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.intended_travel_date && (
                    <tr>
                      <td>Intended travel date</td>
                      <td>
                        {OrderDetails?.intended_travel_date}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.address_line1 && (
                    <tr>
                      <td>Address line 1</td>
                      <td>{OrderDetails?.address_line1}</td>
                    </tr>
                  )}

                  {OrderDetails?.address_line2 && (
                    <tr>
                      <td>Address line 2 (optional)</td>
                      <td>{OrderDetails?.address_line2}</td>
                    </tr>
                  )}

                  {OrderDetails?.address_line3 && (
                    <tr>
                      <td>Address line 3 (optional)</td>
                      <td>{OrderDetails?.address_line3}</td>
                    </tr>
                  )}

                  {OrderDetails?.town_and_city && (
                    <tr>
                      <td>Town or city</td>
                      <td>{OrderDetails?.town_and_city}</td>
                    </tr>
                  )}

                  {OrderDetails?.post_code && (
                    <tr>
                      <td>Postal code or zip code (optional)</td>
                      <td>{OrderDetails?.post_code}</td>
                    </tr>
                  )}

                  {OrderDetails?.country && (
                    <tr>
                      <td>Country</td>
                      <td>{OrderDetails?.country}</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <StyledOrderBlock>
            <h2>Additional Questions</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                
                  {OrderDetails?.other_nationalities_than_qatar_section_details && (
                    <tr>
                      <td>
                        Do you have other nationalities ?<br/> 
                        (Include any nationalities you have had in the past)
                      </td>
                      <td>{OrderDetails?.other_nationalities_than_qatar_section_details}</td>
                    </tr>
                  )}

                  {OrderDetails?.other_nationalities_than_qatar_section_details == "yes" && OrderDetails?.other_nationalities && (
                    <tr>
                      <td>
                        What is your other nationality ?<br/> 
                        (If you have additional nationalities, you will be able to add these later.)
                      </td>
                      <td>{OrderDetails?.other_nationalities}</td>
                    </tr>
                  )}

                  {OrderDetails?.employment_section_details && (
                    <tr>
                      <td>
                        Do you have a job ? 
                      </td>
                      <td>{OrderDetails?.employment_section_details}</td>
                    </tr>
                  )}
                   
                  {OrderDetails?.job && (
                    <tr>
                      <td>
                        Enter your job 
                      </td>
                      <td>{OrderDetails?.job}</td>
                    </tr>
                  )}

                  {OrderDetails?.criminal_conviction && (
                    <tr>
                      <td>Have you ever had a criminal conviction ? </td>
                      <td>{OrderDetails?.criminal_conviction}</td>
                    </tr>
                  )}

                  {OrderDetails?.crime_convict_in_last_12month && (
                    <tr>
                      <td>Have you been convicted of a crime in last 12 months ?</td>
                      <td>
                        {OrderDetails?.crime_convict_in_last_12month}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.brief_description_of_crime && (
                    <tr>
                      <td> Your criminal conviction in the last 12 months ?</td>
                      <td>
                        {OrderDetails?.brief_description_of_crime}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.brief_description_of_crime && (
                    <tr>
                      <td> Your criminal conviction in the last 12 months ?<br/>
                      What crime you convicted for ?
                      </td>
                      <td>
                        {OrderDetails?.brief_description_of_crime}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.convicted_country && (
                    <tr>
                      <td> Which country were you convicted in ?</td>
                      <td>
                        {OrderDetails?.convicted_country}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.sentenced_for_12_months_in_prison && (
                    <tr>
                      <td> Were you sentenced more than 12 months in prison ?</td>
                      <td>
                        {OrderDetails?.sentenced_for_12_months_in_prison}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.convicted_any_other_crime_in_last_12_month && (
                    <tr>
                      <td>Have you been convicted of any other crime in the last 12 months?</td>
                      <td>
                        {OrderDetails?.convicted_any_other_crime_in_last_12_month}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.convicted_any_other_crime && (
                    <tr>
                      <td>Have you been convicted of any other crime in the last 12 months?</td>
                      <td>
                        {OrderDetails?.convicted_any_other_crime}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.other_brief_description_of_crime && (
                    <tr>
                      <td>Your prison sentence of over 12 months?<br/>What crime you convicted for ?</td>
                      <td>
                        {OrderDetails?.other_brief_description_of_crime}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.convict_date && (
                    <tr>
                      <td>What date you were convicted ? </td>
                      <td>
                        {`${OrderDetails?.convict_date}${OrderDetails?.convict_month}${OrderDetails?.convict_year}`}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.prison_convicted_country && (
                    <tr>
                      <td>Which country were you convicted in ? </td>
                      <td>
                        {OrderDetails?.prison_convicted_country}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.suspected_and_involved_section && (
                    <tr>
                      <td>Have you ever been involved in, or suspected in crimes ? </td>
                      <td>
                        {OrderDetails?.suspected_and_involved_section}
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.select_option_for_crime_involvement && (
                    <tr>
                      <td>Which of these have you been involved in ? </td>
                      <td>
                        {OrderDetails?.select_option_for_crime_involvement}
                      </td>
                    </tr>
                  )}
                    
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>

          <StyledOrderBlock>
            <h2>Applicant And Passport Image</h2>
            <div className="table-block table-responsive">
              <table>
                <tbody>
                  {OrderDetails?.passport_image && (
                    <tr>
                      <td>Upload or take a photo of your passport</td>
                      <td>
                        <a
                          href={`https://uk-eta.online/uploads/passport/${OrderDetails?.passport_image}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}

                  {OrderDetails?.upload_image && (
                    <tr>
                      <td>Upload or take a photo of yourself </td>
                      <td>
                        <a
                          href={`https://uk-eta.online/uploads/customer/${OrderDetails?.upload_image}`}
                          target="_blank"
                          // download
                          rel="noreferrer"
                        >
                          Click here
                        </a>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
          <AddComment />
          <StyledOrderBlock>
            <h2>Comment History</h2>
            <div className="table-block table-responsive">
              <table>
                <thead>
                  <tr>
                    <th width="10%">#</th>
                    <th width="20%">Order ID</th>
                    <th width="20%">Date & Time</th>
                    <th width="15%">Team</th>
                    <th width="35%">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof OrderDetails !== "undefined" &&
                    OrderDetails.remark ? (
                    OrderDetails.remark?.map((item, i) => {
                      return (
                        <tr key={i}>
                          <td>{i + 1}</td>
                          <td>{item?.order_id}</td>
                          <td>
                            {moment(item?.us_date).format("DD-MM-YYYY")}
                            {"  "}
                            {item?.us_time}
                          </td>
                          <td>{item?.remark_name}</td>
                          <td>
                            {selectedData.showData &&
                              selectedData.dataId === item.id ? (
                              <div
                                rel="40"
                                className="btn blue-btn"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                              >
                                {" "}
                                Hide
                              </div>
                            ) : (
                              <div
                                rel="40"
                                onClick={() =>
                                  setSelectedData({
                                    showData: !selectedData.showData,
                                    dataId: item?.id,
                                  })
                                }
                                className="btn blue-btn"
                              >
                                {" "}
                                Show
                              </div>
                            )}
                            <br />
                            {selectedData.showData &&
                              selectedData.dataId === item.id && (
                                <div id="40">
                                  <textarea
                                    name="remark"
                                    className="form-control"
                                    defaultValue={item?.remark}
                                  ></textarea>
                                  <b>
                                    Whatsapp:
                                    {item?.whatsapp ? item?.whatsapp : "N"}
                                  </b>
                                  <b>
                                    Telephone:
                                    {item?.telephone ? item?.telephone : "N"}
                                  </b>
                                  <b>
                                    Refund:
                                    {item?.status_refund
                                      ? item?.status_refund
                                      : "N"}
                                  </b>
                                  <b>
                                    Voided:
                                    {item?.voided ? item?.voided : "N"}
                                  </b>
                                  <b>
                                    Chargeback:
                                    {item?.chargeback ? item?.chargeback : "N"}
                                  </b>
                                  <b>
                                    ReportError:
                                    {item?.report_error
                                      ? item?.report_error
                                      : "N"}
                                  </b>
                                </div>
                              )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td colSpan="5" align="center">
                        No record found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </StyledOrderBlock>
        </div>

        <StyledBottomBlock>
          <button
            type="button"
            className="blue-btn"
            data-toggle="modal"
            data-target="#refundModal"
            onClick={() => setShow(true)}
          >
            View Email Content{" "}
          </button>

          <MyModal
            show={show}
            close={setShow}
            size={"lg"}
            modalHeadTitle={"Email Content"}
            showFooter={true}
            primaryButtonName={"Close"}
            secondaryButtonName={"Send Whatsapp Message"}
            OrderDetails={OrderDetails}
          >
            <EmailContent />
          </MyModal>
        </StyledBottomBlock>
      </div>
    </>
  );
};

export default ViewOrders;
